import * as React from "react"
import { usePrismicPreview } from "gatsby-source-prismic"
import PageTemplate from "../templates/page"

import get from "lodash.get"
import { linkResolver } from "../../prismic.config"
export default function PreviewPage() {
  const { isLoading, isPreview, previewData, path } = usePrismicPreview({
    repositoryName: "marketinggreatcontent",
    linkResolver: ({ node, key, value }) => (doc) => {
      return linkResolver(doc)
    },
  })
  if (isLoading) return <div>Loading…</div>

  if (isPreview && !isLoading) {
    return (
      <>
        {" "}
        {
          <PageTemplate
            data={{
              prismicPage:
                get(previewData, "prismicPage") ||
                get(previewData, "prismicPost"),
            }}
          />
        }
      </>
    )
  }

  return null
}
